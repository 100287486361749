const getters = {
  token: state => state.user.token,
  name: state => state.user.nickname,
  username: state => state.user.username,
  avatar: state => state.user.avatar,
  roles: state => state.user.roles,
  activeIndex: state => state.nav.activeIndex,
  eduRole: state => state.user.eduRole,
}

export default getters