import Vue from 'vue'
import checkPermission from '../utils/permission'

export default function directive() {
  Vue.directive('permission', {
    inserted: (el, binding) => {
      if (!checkPermission(binding.value)) {
        el.remove();
      }
    }
  })
}